import { Button, Slider, styled, withStyles } from '@material-ui/core';
import {
  PauseCircleFilled,
  PlayCircleFilled,
  SkipNext,
  SkipPrevious,
} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { calcSizeBasedInVhScale } from '@utils/metrics';
import Image from 'next/image';

interface IContainerProps {
  showPlayer: boolean;
}

export const Container = styled('div')(({ showPlayer }: IContainerProps) => ({
  width: '100%',
  backgroundColor: '#010109',
  color: 'white',
  display: showPlayer ? 'flex' : 'none',
  justifyContent: 'space-between',
  height: calcSizeBasedInVhScale(144),
  overflow: 'hidden',

  '@media screen and (max-width: 900px)': {
    height: calcSizeBasedInVhScale(80),
  },

  zIndex: 99,
  position: 'fixed',
  bottom: 0,
}));

export const BackgroundImg = styled('div')(() => ({
  minWidth: calcSizeBasedInVhScale(83),
  width: calcSizeBasedInVhScale(83),
  height: calcSizeBasedInVhScale(83),
  borderRadius: '20px',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.11)',
  position: 'relative',
  '@media screen and (max-width: 900px)': {
    minWidth: calcSizeBasedInVhScale(60),
    width: calcSizeBasedInVhScale(60),
    height: calcSizeBasedInVhScale(60),
  },
}));

export const Img = styled(Image)(() => ({
  borderRadius: 10,
}));

export const AudioInfoContainer = styled('div')(() => ({
  minWidth: 300,
  width: '20%',
  maxWidth: 500,
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
  gap: 16,
  padding: calcSizeBasedInVhScale(30),
  overflow: 'hidden',
  '@media screen and (max-width: 900px)': {
    padding: calcSizeBasedInVhScale(15),
    maxWidth: '50%',
    minWidth: '50%',
  },
}));

export const AudioInfoText = styled('div')(() => ({
  '@media screen and (max-width: 900px)': {
    display: 'none',
  },
}));

export const AudioInfoTextMobile = styled('div')(() => ({
  width: '100%',

  '@media screen and (min-width: 900px)': {
    display: 'none',
  },
}));

export const AudioTitle = styled('span')(() => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(20),
  fontWeight: 600,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  '-webkit-box-orient': 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',

  '@media screen and (max-width: 900px)': {
    display: 'inline-block',
    whiteSpace: 'nowrap',
  },
}));

export const AudioAuthor = styled('span')(() => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(16),

  '@media screen and (max-width: 900px)': {
    display: 'none',
  },
}));

export const ControlsContainer = styled('div')(() => ({
  minWidth: 300,
  flexGrow: 1,
  padding: '0px 32px',
  marginTop: calcSizeBasedInVhScale(30),

  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'top',

  '@media screen and (max-width: 900px)': {
    marginTop: 0,
    paddingLeft: 16,
    minWidth: 'initial',
    justifyContent: 'center',
  },
}));

export const TimeSliderContainer = styled('div')(() => ({
  display: 'flex',
  gap: 8,
  alignItems: 'center',

  '@media screen and (max-width: 900px)': {
    display: 'none',
  },
}));

export const TimeSliderText = styled('span')(() => ({
  fontFamily: 'Barlow',
  fontSize: calcSizeBasedInVhScale(18),
  minWidth: calcSizeBasedInVhScale(50),
  '@media screen and (max-width: 900px)': {
    position: 'relative',
    top: -4,
    fontSize: calcSizeBasedInVhScale(16),
  },
}));

export const SliderComponent = withStyles({
  root: {
    color: '#0080ED',
    height: 8,
    padding: 0,
    '@media screen and (max-width: 900px)': {
      display: 'none',
    },
  },
  thumb: {
    height: 8,
    width: 8,
    border: '2px solid currentColor',
    marginTop: -2,
    marginLeft: -4,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 4,
    borderRadius: 4,
  },
  rail: {
    height: 4,
    borderRadius: 4,
  },
})(Slider);

export const ControlsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& button': {
    minWidth: 20,
  },

  '@media screen and (max-width: 900px)': {
    padding: 0,
  },
}));

export const RightContainer = styled('div')(() => ({
  minWidth: 300,
  width: '20%',
  maxWidth: 500,

  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: 60,

  '@media screen and (max-width: 900px)': {
    display: 'none',
  },
}));

export const VolumeContainer = styled('div')(() => ({
  maxWidth: 160,
  minWidth: 160,
  display: 'flex',
  alignItems: 'center',
  gap: 8,
}));

export const CloseButton = styled(Button)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  margin: 12,
  color: 'white',
  border: '1px solid white',
  borderRadius: calcSizeBasedInVhScale(30),
  maxWidth: calcSizeBasedInVhScale(30),
  minWidth: calcSizeBasedInVhScale(30),
  maxHeight: calcSizeBasedInVhScale(30),

  '@media screen and (max-width: 900px)': {
    margin: 4,
    padding: 0,
    borderRadius: calcSizeBasedInVhScale(20),
    maxWidth: calcSizeBasedInVhScale(20),
    minWidth: calcSizeBasedInVhScale(20),
    maxHeight: calcSizeBasedInVhScale(20),
  },
}));

export const PauseIcon = styled(PauseCircleFilled)(() => ({
  color: 'white',
  fontSize: 40,

  '@media screen and (max-width: 900px)': {
    fontSize: calcSizeBasedInVhScale(60),
  },
}));

export const PlayIcon = styled(PlayCircleFilled)(() => ({
  color: 'white',
  fontSize: 40,

  '@media screen and (max-width: 900px)': {
    fontSize: calcSizeBasedInVhScale(60),
  },
}));

export const NextIcon = styled(SkipNext)(() => ({
  color: 'white',
  fontSize: 20,

  '@media screen and (max-width: 900px)': {
    fontSize: calcSizeBasedInVhScale(40),
  },
}));

export const PreviousIcon = styled(SkipPrevious)(() => ({
  color: 'white',
  fontSize: 20,

  '@media screen and (max-width: 900px)': {
    fontSize: calcSizeBasedInVhScale(40),
  },
}));

export const ClosePlayerIcon = styled(CloseIcon)(() => ({
  color: 'white',
  fontSize: 16,

  '@media screen and (max-width: 900px)': {
    fontSize: 10,
  },
}));

export const MainButton = styled(Button)(() => ({
  padding: '0 8px 6px',

  '@media screen and (max-width: 900px)': {
    padding: '0 8px',
  },
}));
