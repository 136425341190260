import { Tooltip } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ScrollText from 'react-scroll-text';
import { usePodcastContext } from '@hooks/podcast';
import { ITrack } from '@models/player.interface';
import { VolumeUp } from '@material-ui/icons';
import { getEpisodeCache, setEpisodeCache } from '@services/podcastCache';
import Controls from './Controls';
import {
  AudioAuthor,
  AudioInfoContainer,
  AudioTitle,
  BackgroundImg,
  Container,
  ControlsContainer,
  Img,
  SliderComponent,
  TimeSliderContainer,
  TimeSliderText,
  RightContainer,
  VolumeContainer,
  CloseButton,
  ClosePlayerIcon,
  AudioInfoText,
  AudioInfoTextMobile,
} from './styles';

const PodcastPlayer: React.FC = () => {
  const {
    trackIndex,
    setTrackIndex,
    trackQueue,
    isPlaying,
    setIsPlaying,
    showPlayer,
    setShowPlayer,
    currentTime,
    setCurrentTime,
    onPrev,
    onNext,
    onClose,
  } = usePodcastContext();
  const UPDATE_CACHE_RATE = 30;

  const [track, setTrack] = useState<ITrack>(undefined);
  const [volume, setVolume] = useState(0.65);
  const [lastCurrentTime, setLastCurrentTime] = useState(0);

  const audioRef = useRef<HTMLAudioElement | undefined>(
    typeof Audio !== 'undefined' ? new Audio(track?.audioUrl) : undefined,
  );
  const intervalRef = useRef(null);
  const isReady = useRef(false);

  const duration = useMemo(() => {
    if (track && audioRef && audioRef.current && audioRef.current.duration)
      return audioRef.current.duration;

    return track?.duration ? track?.duration : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track, isPlaying, audioRef]);

  const getCachedTime = trackId => {
    return getEpisodeCache(trackId).currentTime;
  };

  const toPrevTrack = useCallback(() => {
    if (onPrev) {
      onPrev();
      return;
    }

    if (trackIndex > 0) {
      setTrackIndex(trackIndex - 1);
    }
  }, [onPrev, setTrackIndex, trackIndex]);

  const toNextTrack = useCallback(() => {
    if (onNext) {
      onNext();
      return;
    }

    if (trackIndex < trackQueue.length - 1) {
      setTrackIndex(trackIndex + 1);
    }
  }, [onNext, setTrackIndex, trackIndex, trackQueue.length]);

  const onScrub = (event, value) => {
    clearInterval(intervalRef.current);
    audioRef.current.currentTime = value;
    setCurrentTime(audioRef.current.currentTime);
  };

  const onScrubEnd = () => {
    if (!isPlaying) {
      setIsPlaying(true);
    }
    startTimer();
  };

  const handleVolumeChange = (event, value) => {
    setVolume(value);
    audioRef.current.volume = value;
  };

  const startTimer = useCallback(() => {
    clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      if (!audioRef) return;

      if (audioRef.current.ended) {
        toNextTrack();
      } else {
        setCurrentTime(audioRef.current.currentTime);
      }
    }, 1000);
  }, [setCurrentTime, toNextTrack, audioRef]);

  const secondsToTime = seconds => {
    if (!seconds) return '--:--';

    const hours = Math.floor(seconds / 3600);
    const min = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    const sec = String(Math.floor(seconds % 60)).padStart(2, '0');

    return hours > 0 ? `${hours}:${min}:${sec}` : `${min}:${sec}`;
  };

  useEffect(() => {
    if (isPlaying && !showPlayer) {
      setIsPlaying(false);
    }

    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      clearInterval(intervalRef.current);
      audioRef.current.pause();
    }
  }, [audioRef, isPlaying, setIsPlaying, showPlayer, startTimer]);

  useEffect(() => {
    setTrack(trackQueue[trackIndex]);
  }, [trackIndex, trackQueue]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef.current.pause();
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    if (!track || !audioRef) return;
    audioRef.current.pause();
    audioRef.current = new Audio(track.audioUrl);
    audioRef.current.currentTime = getCachedTime(track?.id);
    setLastCurrentTime(audioRef.current.currentTime);
    setCurrentTime(audioRef.current.currentTime);
    if (isReady.current) {
      audioRef.current.play();
      setIsPlaying(true);
      startTimer();
    } else {
      isReady.current = true;
    }
  }, [setCurrentTime, setIsPlaying, startTimer, track, audioRef, isReady]);

  useEffect(() => {
    if (currentTime > lastCurrentTime + UPDATE_CACHE_RATE) {
      setLastCurrentTime(currentTime);
      setEpisodeCache(track.id, { currentTime });
    }
  }, [currentTime, lastCurrentTime, track]);

  const titleWithScroll = useMemo(() => {
    return (
      <ScrollText speed={20} style={{ position: 'relative', top: 5 }}>
        <AudioTitle>{track?.title}</AudioTitle>
      </ScrollText>
    );
  }, [track?.title]);

  return (
    <Container showPlayer={showPlayer}>
      <AudioInfoContainer>
        <BackgroundImg>
          <Img
            src={track?.imageUrl || '/share.webp'}
            layout="fill"
            objectFit="cover"
            quality="50"
          />
        </BackgroundImg>

        <AudioInfoText>
          <Tooltip title={track?.title}>
            <AudioTitle>{track?.title}</AudioTitle>
          </Tooltip>
          <AudioAuthor>{track?.author}</AudioAuthor>
        </AudioInfoText>
        <AudioInfoTextMobile>
          {titleWithScroll}
          <TimeSliderText>
            {secondsToTime(currentTime)}-{secondsToTime(duration)}
          </TimeSliderText>
        </AudioInfoTextMobile>
      </AudioInfoContainer>

      <ControlsContainer>
        <Controls
          isPlaying={isPlaying}
          onPrevClick={toPrevTrack}
          onNextClick={toNextTrack}
          onPlayPauseClick={setIsPlaying}
        />
        <TimeSliderContainer>
          <TimeSliderText>{secondsToTime(currentTime)}</TimeSliderText>
          <SliderComponent
            min={0}
            max={typeof duration === 'number' ? duration : track?.duration}
            value={currentTime}
            step={1}
            onChange={onScrub}
            onChangeCommitted={onScrubEnd}
          />
          <TimeSliderText>{secondsToTime(duration)}</TimeSliderText>
        </TimeSliderContainer>
      </ControlsContainer>

      <RightContainer>
        <VolumeContainer>
          <VolumeUp style={{ marginBottom: 2 }} />
          <SliderComponent
            min={0}
            max={1}
            value={volume}
            step={0.05}
            onChange={handleVolumeChange}
          />
        </VolumeContainer>
      </RightContainer>
      <CloseButton
        onClick={() => {
          if (onClose) {
            onClose();
            return;
          }

          setShowPlayer(false);
        }}
      >
        <ClosePlayerIcon />
      </CloseButton>
    </Container>
  );
};

export default PodcastPlayer;
