import { usePodcastContext } from '@hooks/podcast';
import { Button } from '@material-ui/core';
import {
  ControlsWrapper,
  MainButton,
  NextIcon,
  PauseIcon,
  PlayIcon,
  PreviousIcon,
} from '../styles';

export interface IControlsProps {
  isPlaying: boolean;
  onPlayPauseClick: (boolean) => void;
  onPrevClick: () => void;
  onNextClick: () => void;
}

const Controls: React.FC<IControlsProps> = ({
  isPlaying,
  onPlayPauseClick,
  onPrevClick,
  onNextClick,
}) => {
  const { onPause, onPlay } = usePodcastContext();
  return (
    <>
      <ControlsWrapper>
        <Button color="primary" onClick={onPrevClick}>
          <PreviousIcon />
        </Button>
        {isPlaying ? (
          <MainButton
            color="primary"
            onClick={() => {
              if (onPause) {
                onPause();
                return;
              }

              onPlayPauseClick(false);
            }}
          >
            <PauseIcon />
          </MainButton>
        ) : (
          <MainButton
            color="primary"
            onClick={() => {
              if (onPlay) {
                onPlay();
                return;
              }

              onPlayPauseClick(true);
            }}
          >
            <PlayIcon />
          </MainButton>
        )}
        <Button color="primary" onClick={onNextClick}>
          <NextIcon />
        </Button>
      </ControlsWrapper>
    </>
  );
};

export default Controls;
